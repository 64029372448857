.row-admin {
  position: fixed;
  top: 60px;
  left: 0;
  bottom: 0;
  max-height: calc(100vh - 60px);
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}

.sidebar {
  overflow-y: scroll;
  height: 100%;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.sidebar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.sidebar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.bgdark {
  background-color: #343a40;
}

/* responsiveness*/
/* .sidebar {
    min-width: 200px;
} */
/* .content {
  overflow-x: scroll;
} */

.list-group-item {
  cursor: pointer;
}