.mytable {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  
  .mytable td, .mytable th {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  /* .mytable tr:nth-child(even){background-color: #f2f2f2;} */
  
  .mytable tr:hover {background-color: #ddd;}
  
  .mytable th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #f2f2f2;
    color: black;
    position: sticky;
    top: 0;
  }

.l-height {
  line-height: 2rem;
}

/*test*/
#extra {
  display: none;
}

/*booking detail*/
.back {
  position: absolute;
  top: 10px;
  left: 10px;
}