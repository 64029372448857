.sidebar {
    overflow-y: scroll;
    overflow-x: visible;
    height: 100%;
}
.content {
    overflow-x: auto;
    height: 100%;
}

/*sidebar collapse */

a:hover {
    text-decoration: none;
}

/* Hide scrollbar for Chrome, Safari and Opera */
/* .sidebar::-webkit-scrollbar {
    display: none;
  }
   */
  /* Hide scrollbar for IE, Edge and Firefox */
  /* .sidebar {
    -ms-overflow-style: none;  /* IE and Edge */
    /* scrollbar-width: none;  Firefox */
   */

/* .badge {
    margin-left: 20px;
    padding: 3px 10px;
    background-color: #007bff !important;
} */

.bgdark {
    background-color: #343a40;
}


/* responsiveness*/
/* .sidebar {
    min-width: 200px;
} */
/* .content {
    overflow-x: scroll;
} */

