html {
  min-height: 100vh;
}
body {
  margin: 0;
  overflow-x: hidden;
  height: 100vh;
}
#root {
  height: 100%;
}
.homepage-bg {
  background-image: url('../assets/checkout-bg.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

#logo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: max-content;
  height: max-content;
}

.logo-letter {
  color: #f9ff00;
  font-family: 'Concert One', sans-serif;
  width: max-content;
  height: max-content;
  font-size: 5rem;
  font-size: clamp(5rem, 3.4rem + 8vw, 13rem);
  text-shadow: -7px 7px 0px #ff5f92;
}

.jump {
  animation-duration: 2s;
  animation-iteration-count: 1;
  animation-direction: forwards;
  transform-origin: top;
  animation-name: bounce;
  animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
}

@keyframes bounce {
  0% {
    transform: scale(1, 1) translateY(0);
  }
  10% {
    transform: scale(1.1, 0.9) translateY(0);
  }
  30% {
    transform: scale(0.9, 1.1) translateY(-100px);
  }
  50% {
    transform: scale(1.05, 0.95) translateY(0);
  }
  57% {
    transform: scale(1, 1) translateY(-7px);
  }
  64% {
    transform: scale(1, 1) translateY(0);
  }
  100% {
    transform: scale(1, 1) translateY(0);
  }
}

#homepage-btn {
  box-sizing: content-box;
  width: max-content;
  color: #ff5f92;
  font-size: 1.4em;
  font-weight: 700;
  text-transform: uppercase;
  padding: 1rem 2.5rem;
  background-image: radial-gradient(
      circle at 0% 50%,
      transparent 0.5rem,
      #ffffff 0.6rem
    ),
    radial-gradient(circle at 0% 50%, transparent 0.5rem, #ffffff 0.6rem),
    radial-gradient(circle at 100% 50%, transparent 0.5rem, #ffffff 0.6rem),
    radial-gradient(circle at 100% 50%, transparent 0.5rem, #ffffff 0.6rem);
  background-size: 51% 51%, 51% 51%, 51% 51%, 51% 51%;
  background-repeat: no-repeat;
  background-position: top left, bottom left, top right, bottom right;
  transition: 0.2s all ease;
}

#homepage-btn:hover,
#homepage-btn:active {
  color: #ffffff;
  cursor: pointer;
  background-image: radial-gradient(
      circle at 0% 50%,
      transparent 0.5rem,
      #ff5f92 0.6rem
    ),
    radial-gradient(circle at 0% 50%, transparent 0.5rem, #ff5f92 0.6rem),
    radial-gradient(circle at 100% 50%, transparent 0.5rem, #ff5f92 0.6rem),
    radial-gradient(circle at 100% 50%, transparent 0.5rem, #ff5f92 0.6rem);
}

.checkout-bg {
  background-image: linear-gradient(
    165deg,
    hsl(301deg 94% 35%) 0%,
    hsl(286deg 94% 36%) 5%,
    hsl(270deg 93% 38%) 13%,
    hsl(255deg 92% 39%) 24%,
    hsl(239deg 91% 41%) 35%,
    hsl(224deg 91% 42%) 48%,
    hsl(208deg 90% 44%) 61%,
    hsl(193deg 89% 45%) 75%,
    hsl(178deg 88% 47%) 88%,
    hsl(162deg 88% 48%) 100%
  );
  background-repeat: repeat;
  height: auto;
  min-height: 100vh;
}

.bubble {
  position: absolute;
  font-family: 'Concert One', sans-serif;
  font-size: 28px;
  line-height: 30px;
  height: min-content;
  background: #fff;
  border-radius: 40px;
  padding: 0.2rem 1rem 1rem 1rem;
  text-align: center;
  font-weight: bold;
  color: #000;
  top: 45px;
  left: -25px;
}

.bubble-bottom-left:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 32px solid #fff;
  border-right: 20px solid transparent;
  border-top: 20px solid #fff;
  border-bottom: 28px solid transparent;
  right: 0;
  bottom: -10px;
  transform: rotate(60deg);
}